import '../css/modal/ReusableModal.css';
import React, { useEffect, useState, useRef } from 'react';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ReusableModal = ({ showModal, fieldName, formName, fieldValue, onClose, onSelect }) => {
  const [gridData, setGridData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const searchInputRef = useRef(null); // Create a ref for the search input

  useEffect(() => {
    if (showModal) {
      const url = `${API_BASE_URL}/api/data`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formName, fieldName, fieldValue }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          return response.json();
        })
        .then((data) => setGridData(data))
        .catch((error) => console.error('Error fetching data:', error));
    }
  }, [showModal, fieldName, formName, fieldValue]);

  // const filteredData = gridData.filter((row) =>
  //   Object.values(row).some((value) =>
  //     value.toLowerCase().includes(searchQuery.toLowerCase())
  //   )
  // );
  const filteredData = gridData.filter((row) =>
    Object.values(row).some((value) =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    // Focus on the search input whenever the modal is opened
    if (showModal && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showModal]);

  return showModal ? (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h4>Select {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}</h4>
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control"
          ref={searchInputRef} // Attach ref to the search input
        />
        <table className="table table-bordered">
          <thead>
            <tr>
              {gridData.length > 0 &&
                Object.keys(gridData[0]).map((key) => <th key={key}>{key}</th>)}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, index) => (
              <tr
                key={index}
                onClick={() => onSelect(fieldName, row)}  // Pass entire row to handleSelect
              >
                {Object.values(row).map((value, idx) => (
                  <td key={idx}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};

export default ReusableModal;