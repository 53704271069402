// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const App = () => {
//   const [message, setMessage] = useState('');

//   useEffect(() => {
//     const fetchMessage = async () => {
//       try {
//         const response = await axios.get('http://localhost:5000');
//         setMessage(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchMessage();
//   }, []);

//   return (
//     <div>
//       <h1>React Frontend</h1>
//       <p>{message}</p>
//     </div>
//   );
// };

// export default App;

import React from 'react';
import AppRoutes from './routes/AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
