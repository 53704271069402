import React, { useEffect, useState } from 'react';
import ReusableModal from '../components/modal/ReusableModal';
import axios from 'axios';
import '../components/css/pages/SQCReport.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SqcReport = () => {
  const sessionUserDetials = JSON.parse(sessionStorage.getItem('user'));
  const CompanyName = sessionUserDetials.compName;
  const Location = sessionUserDetials.location;
  const CompanyCode = sessionUserDetials.compCode;
  const userID = sessionUserDetials.userID;

  const [defaultDate, setDefaultDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formats as YYYY-MM-DD
  });

  const formatDate = (date) => {
    if (!date) return ""; // Handle null/undefined dates
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  };

  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    formName: '',
    fieldName: '',
    fieldValue: '',
  });

  const handleFieldDoubleClick = (formName, fieldName, fieldValue) => {
    // Check if the field already has a value
    if (
      (fieldName === 'QualityName' && formData.qualityName)
      //  ||
      // (fieldName === 'MachineName' && formData.machineName)
    ) {
      // console.log(`Double-click prevented. ${fieldName} is already selected.`);
      return; // Do not open the modal
    }

    // Proceed to open the modal if no value is selected
    setModalConfig({
      showModal: true,
      formName,
      fieldName,
      fieldValue,
    });
  };

  const handleSelect = (fieldName, selectedRow) => {
    // console.log(fieldName, selectedRow);

    // Assuming selectedRow is an object containing department, departmentCode, and process
    if (fieldName === 'mainShift') {
      setFormData((prev) => ({
        ...prev,
        mainShift: selectedRow.Shift,
      }));
    }

    if (fieldName === 'Department') {
      setFormData((prev) => ({
        ...prev,
        department: selectedRow.Department,
        departmentCode: selectedRow.Code,  // Assuming 'Code' is the department code in your data
        process: selectedRow.Process
      }));
    }

    if (fieldName === 'QualityName') {
      setFormData((prev) => ({
        ...prev,
        qualityCode: selectedRow.id,
        qualityName: selectedRow.QualityName,
        // SampleLengthYDS: selectedRow.SampleLength,
        // FixedForHessianbySaking16and20: selectedRow.FixedForHessianSaking,
        // WtYards: selectedRow.WtPerYards,
        // Constant: selectedRow.Constant,
        // Fixed: selectedRow.Fixed,
      }));
    }

    if (fieldName === 'MachineName') {
      // console.log('At Machine', selectedRow.mcode);
      setFormData((prev) => ({
        ...prev,
        machineCode: selectedRow.mcode,
        machineName: selectedRow.mname,
      }));
    }

    // if (fieldName === 'MachineName') {
    //   // console.log('At Machine', selectedRow.mcode);
    //   setFormData((prev) => ({
    //     ...prev,
    //     machineCode: selectedRow.mcode,
    //     machineName: selectedRow.mname,
    //   }));
    // }

    // Close the modal after selection
    setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' });
  };

  const [formData, setFormData] = useState({
    compCode: CompanyCode,
    compName: CompanyName,
    location: Location,
    userid: userID,
    // reportHeader: 'Report - 1 Date Wise',
    reportHeader: '',
    rptName: '',
    fromDate: defaultDate,
    toDate: defaultDate,
    mainShift: '',
    department: '',
    departmentCode: '',
    machineName: '',
    machineCode: '',
    qualityName: '',
    qualityCode: '',
    process: '',
  })

  const generatePDF = async () => {
    try {
      if (formData.rptName === '' || formData.department === '') {
        alert('Please select report type and department field!');
        return;
      }
      const response = await axios.post(`${API_BASE_URL}/generate-report`, {
        compName: formData.compName,
        reportHeader: formData.reportHeader,
        rptName: formData.rptName,
        address: formData.location,
        fromDate: formData.fromDate,
        toDate: formData.toDate,
        deptName: formData.department,
        mchCode: formData.machineCode,
        qCode: formData.qualityCode,
        qName: formData.qualityName
      }, { responseType: 'blob' });

      // Create a new Blob for the PDF
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error("Error generating PDF:", error);
      alert('There was an error generating the report.');
    }
  };

  // http://localhost:5000 -- Starting of TreeView

  // TreeNode component to render each individual node
  const TreeNode = ({ node, onSelect }) => {
    // Handle selection of a node
    const handleClick = () => {
      onSelect(node.RptName, node.ReportName);  // Pass both RptName and ReportName when node is clicked
    };

    return (
      <li className='mb-2' onClick={handleClick} style={{ cursor: 'pointer' }}>
        <span>{node.ReportName}</span>
        {/* Hidden attribute can be added if necessary */}
        <input type="hidden" value={node.RptName} />
      </li>
    );
  };

  // TreeView component to render the entire tree structure
  const TreeView = ({ data, onSelect }) => {
    return (
      <div>
        {Object.keys(data).map((category) => (
          <div key={category}>
            <h5>{category}</h5>
            <ul>
              {data[category].map((node) => (
                <TreeNode key={node.ChildNodeID} node={node} onSelect={onSelect} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  const [selectedRptName, setSelectedRptName] = useState(null);  // State to hold selected RptName
  const [selectedReportName, setSelectedReportName] = useState(null);  // State to hold selected ReportName

  // Handle node selection
  const handleNodeSelect = (RptName, ReportName) => {
    setSelectedRptName(RptName);
    setSelectedReportName(ReportName);
    setFormData((prev) => ({
      ...prev,
      reportHeader: ReportName,
      rptName: RptName,
    }))
  };

  const [reports, setReports] = useState({});

  useEffect(() => {
    // Sending POST request to fetch the report data
    fetch(`${API_BASE_URL}/api/reports`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),  // Sending empty body for now, adjust if needed
    })
      .then((response) => response.json())
      .then((data) => {
        setReports(data);
      })
      .catch((error) => console.error('Error fetching reports:', error));
  }, []);

  return (
    <div className="">
      <form>
        <div className="d-flex justify-content-around">
          <div className='p-4 m-2 container-report-tree'>
            <TreeView data={reports} onSelect={handleNodeSelect} />
          </div>
          <div className='container p-3'>
            <div className="container-head-details">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="form-group row">
                    <label htmlFor="compName" className='col-md-3 col-lg-3 col-sm-3'>Company</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='compName'
                        className="form-control"
                        value={formData.compName}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="location" className='col-md-3 col-lg-3 col-sm-3'>Location</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='location'
                        className="form-control"
                        value={formData.location}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Container Header */}
            <div className='container-head' style={{ position: 'relative' }}>
              <h4>{selectedReportName || 'Select a Report'}</h4> {/* Display the selected ReportName or a default */}

              {/* If a report is selected, show the label */}
              {selectedRptName && (
                <label className="selected-rptname-label" style={{
                  position: 'absolute',
                  top: '-20px', left: '0',
                  fontSize: '0.8em',
                  color: '#555',
                  backgroundColor: '#fff', // optional for better visibility
                  padding: '2px 5px',
                  borderRadius: '3px'
                }}>
                  {selectedRptName}
                </label>
              )}
            </div>
            <div className="container-details-report-columns">
              <div className="row d-flex justify-content-around">
                <div className="col-md-4 col-lg-3">
                  <div className="form-group row">
                    <label htmlFor="fromDate" className="col-sm-3 col-md-3 col-lg-3">From Date</label>
                    <div className="col-sm-9 col-md-9 col-lg-9">
                      <input
                        type="date"
                        id="fromDate"
                        className="form-control"
                        value={formData.fromDate} // Assuming `fromDate` is a state variable
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            fromDate: e.target.value
                          })
                          )} // Assuming `setFromDate` is the state setter
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-3">
                  <div className="form-group row">
                    <label htmlFor="toDate" className="col-sm-3 col-md-3 col-lg-3">To Date</label>
                    <div className="col-sm-9 col-md-9 col-lg-9">
                      <input
                        type="date"
                        id="toDate"
                        className="form-control"
                        value={formData.toDate} // Assuming `fromDate` is a state variable
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...formData,
                            toDate: e.target.value
                          })
                          )} // Assuming `setFromDate` is the state setter
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container d-flex justify-content-center">
                <div className="p-4">
                  <div className="form-group row">
                    <label htmlFor="mainShift" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Main Shift</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <div className="input-group">
                        <input type="text"
                          id='mainShift'
                          className="form-control"
                          placeholder='Select Shift'
                          value={formData.mainShift}
                          onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'mainShift', '')}
                          // onChange={(e) => setFormData({ ...formData, mainShift: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="department" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Department</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <div className="input-group">
                        <div className="input-group-append w-25">
                          <input type="text"
                            id='departmentCode'
                            className="form-control"
                            placeholder='Code'
                            value={formData.departmentCode}
                            readOnly
                          />
                        </div>
                        <input type="text"
                          id='department'
                          className="form-control"
                          placeholder='Select Department'
                          value={formData.department}
                          onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'Department', '')}
                          // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="machineName" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Machine</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <div className="input-group">
                        <div className="input-group-append w-25">
                          <input type="text"
                            id='machineCode'
                            className="form-control"
                            placeholder='Code'
                            value={formData.machineCode}
                            readOnly
                          />
                        </div>
                        <input type="text"
                          id='machineName'
                          className="form-control"
                          placeholder='Select Machine'
                          value={formData.machineName}
                          onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'MachineName',
                            {
                              machineName: formData.machineName,
                            }
                          )}
                          // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="qualityName" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Quality</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <div className="input-group">
                        <div className="input-group-append w-25">
                          <input type="text"
                            id='qualityCode'
                            className="form-control"
                            placeholder='Code'
                            value={formData.qualityCode}
                            readOnly
                          />
                        </div>
                        <input type="text"
                          id='qualityName'
                          className="form-control"
                          placeholder='Select quality'
                          value={formData.qualityName}
                          onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'QualityName',
                            {
                              departmentCode: formData.departmentCode,
                              department: formData.department,
                              process: formData.process,
                              qualityName: formData.qualityName,
                            }
                          )}
                          // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="yarnName" className='mt-1 col-md-3 col-lg-3 col-sm-3'>yarn</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <div className="input-group">
                        <div className="input-group-append w-25">
                          <input type="text"
                            id='yarnCode'
                            className="form-control"
                            placeholder='Code'
                            value={formData.yarnCode}
                            readOnly
                          />
                        </div>
                        <input type="text"
                          id='yarnName'
                          className="form-control"
                          placeholder='Select yarn'
                          value={formData.yarnName}
                          // onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'YarnName', '')}
                          // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around container-button-details">
              <button type='button' className="btn btn-primary">Display</button>
              <button type="button" className="btn btn-primary" onClick={generatePDF}>PDF</button>
              <button type='button' className="btn btn-primary">Cancel</button>
            </div>
          </div>
        </div>
      </form>
      <ReusableModal
        showModal={modalConfig.showModal}
        fieldName={modalConfig.fieldName}
        formName={modalConfig.formName}
        fieldValue={modalConfig.fieldValue}
        onClose={() => setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' })}
        onSelect={handleSelect}
      />
    </div>
  );
};

export default SqcReport;