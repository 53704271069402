import React, { useState, useEffect } from 'react';
import '../components/css/pages/SQCQualityMaster.css';
// import DropDownBoxTable from '../components/dropdowns/DropDownBoxTable';
import ReusableModal from '../components/modal/ReusableModal';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SqcQualityMaster = () => {
  const sessionUserDetials = JSON.parse(sessionStorage.getItem('user'));
  const userId = sessionUserDetials.userID;

  const [getTableData, setGetTableData] = useState([]); // Original data
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Number of rows per page
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = getTableData.filter(
      (row) =>
        row.Code.toLowerCase().includes(query) ||
        row.Department.toLowerCase().includes(query) ||
        row.Process.toLowerCase().includes(query) ||
        row.QualityName.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = filteredData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (page) => setCurrentPage(page);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/get-sqcqm`, {
          method: 'POST', // Use POST to match the backend
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Check if the response is okay
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setGetTableData(data); // Set the fetched data to your state
        setFilteredData(data); // Initialize filtered data
      } catch (error) {
        console.error('Error fetching SQCQM data:', error);
      }
    };

    // Call fetchData inside useEffect
    fetchData();
  }, []); // Empty dependency array ensures this runs only once on component mount


  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    formName: '',
    fieldName: '',
    fieldValue: '',
  });

  // Modal Open on Double click
  const handleFieldDoubleClick = (formName, fieldName, fieldValue) => {
    setModalConfig({
      showModal: true,
      formName,
      fieldName,
      fieldValue,
    });
  };
  const handleSelect = (fieldName, selectedRow) => {
    if (fieldName === 'Department') {
      setTableData((prevTableData) =>
        prevTableData.map((row, index) => {
          // Assuming the modal is tied to the currently edited row
          if (row.departmentName === '' && row.departmentCode === '') {
            return {
              ...row,
              departmentCode: selectedRow.Code,
              departmentName: selectedRow.Department, // Assuming 'Code' and 'Department' exist in selectedRow
              process: selectedRow.Process,
            };
          }
          return row;
        })
      );
    }
    // Close the modal after selection
    setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' });
  };

  const [tableData, setTableData] = useState([
    {
      departmentCode: '',
      departmentName: '',
      process: '',
      qualityId: '',
      qualityName: '',
      sampleLength: '',
      moistAllowed: '',
      constant: '',
      fixedHessianSaking: '',
      fixed: '',
      wtPerYards: '',
      userId: userId,
    },
  ]);

  useEffect(() => {
    // Debug log to check initial state
    console.log('Initial tableData:', tableData);
  }, []);

  const handleInputChange = (index, field, value) => {
    if (!Array.isArray(tableData)) {
      console.error('tableData is not an array:', tableData);
      return;
    }

    // Create a copy of the current tableData array
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;
    // Update the specific field for the given row and add userId
    // updatedTableData[index] = {
    //   ...updatedTableData[index],
    //   [field]: value,
    //   userId: userId, // Add userId to the row
    // };
    setTableData(updatedTableData);

    // Check if it's the last row and at least one field is filled
    if (index === tableData.length - 1 && Object.values(updatedTableData[index]).some((val) => val !== '')) {
      setTableData([
        ...updatedTableData,
        {
          departmentCode: '',
          departmentName: '',
          process: '',
          qualityId: '',
          qualityName: '',
          sampleLength: '',
          moistAllowed: '',
          constant: '',
          fixedHessianSaking: '',
          fixed: '',
          wtPerYards: '',
          userId: userId,
        },
      ]);
    }
  };

  // State to manage active tab
  const [activeTab, setActiveTab] = useState('entry'); // Default to 'entry' tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    // Filter out rows that are blank
    // const filteredData = tableData.filter((row) =>
    //   Object.values(row).some((value) => value !== '')
    // );

    const filteredData = tableData.filter((row) =>
      Object.entries(row).some(
        ([key, value]) => key !== 'userId' && value !== ''
      )
    );

    console.log('Filtered Form Data at submit time:', filteredData);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/sqc-qualitymaster-insert`, filteredData);
      console.log('Form submitted successfully:', response.data);

      // Show success message
      alert('Data submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);

      // Show error message
      alert('Failed to submit data. Please try again.');
    }
  };

  return (
    <div className='container-form'>
      <div className="container-tab">
        <div className='container-head'>
          <h6>Quality Master</h6>
        </div>
        <ul className='tab-nav'>
          <li
            className={activeTab === 'entry' ? 'active' : ''}
            onClick={() => handleTabClick('entry')}
          >
            <i className='bi bi-pencil-square'></i>
          </li>
          <li
            className={activeTab === 'view' ? 'active' : ''}
            onClick={() => handleTabClick('view')}
          >
            <i className='bi bi-eye'></i>
          </li>
        </ul>
      </div>
      {/* Tab Content */}
      <div className="d-flex justify-content-center">
        <div className='tab-content'>
          {activeTab === 'entry' && (
            <div className='entry-tab'>
              {/* <h6 className="d-flex justify-content-start">Entry</h6> */}
              <form onSubmit={handleSubmitForm}>
                <div className="container-head-details">
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th>Code</th>
                          <th>Department</th>
                          <th>Process</th>
                          <th>ID</th>
                          <th>Quality Name</th>
                          <th>Sample Length</th>
                          <th>Moisture Allowed</th>
                          <th>Constant</th>
                          <th>Fixed For Hessian Saking</th>
                          <th>Fixed</th>
                          <th>Wt Per Yards</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Department Code"
                                value={row.departmentCode}
                                onChange={(e) => handleInputChange(index, 'departmentCode', e.target.value)}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Department Name"
                                value={row.departmentName}
                                onChange={(e) => handleInputChange(index, 'departmentName', e.target.value)}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-- Select Process --"
                                value={row.process}
                                onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'Department', '')}
                                onChange={(e) => handleInputChange(index, 'process', e.target.value)}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter ID"
                                value={row.qualityId}
                                onChange={(e) => handleInputChange(index, 'qualityId', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Quality Name"
                                value={row.qualityName}
                                onChange={(e) => handleInputChange(index, 'qualityName', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Sample Length"
                                value={row.sampleLength}
                                onChange={(e) => handleInputChange(index, 'sampleLength', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Moisture Allowed"
                                value={row.moistAllowed}
                                onChange={(e) => handleInputChange(index, 'moistAllowed', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Constant"
                                value={row.constant}
                                onChange={(e) => handleInputChange(index, 'constant', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Fixed For Hessian Saking"
                                value={row.fixedHessianSaking}
                                onChange={(e) => handleInputChange(index, 'fixedHessianSaking', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Fixed"
                                value={row.fixed}
                                onChange={(e) => handleInputChange(index, 'fixed', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                type='number'
                                className="form-control"
                                placeholder="Enter Wt Per Yards"
                                value={row.wtPerYards}
                                onChange={(e) => handleInputChange(index, 'wtPerYards', e.target.value)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-start mt-3">
                    <button type='submit' className="btn btn-success me-2">Save</button>
                    <button type='button' className="btn btn-secondary">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {activeTab === 'view' && (
            <div className="view-tab">
              <h6 className="d-flex justify-content-center">View Form</h6>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>Code</th>
                      <th>Department</th>
                      <th>Process</th>
                      <th>ID</th>
                      <th>Quality Name</th>
                      <th>Sample Length</th>
                      <th>Moisture Allowed</th>
                      <th>Constant</th>
                      <th>Fixed For Hessian Saking</th>
                      <th>Fixed</th>
                      <th>Wt Per Yards</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Code}</td>
                        <td>{row.Department}</td>
                        <td>{row.Process}</td>
                        <td>{row.ID}</td>
                        <td>{row.QualityName}</td>
                        <td>{row.SampleLength}</td>
                        <td>{row.MoistureAllowed}</td>
                        <td>{row.Constant}</td>
                        <td>{row.FixedForHessianSaking}</td>
                        <td>{row.Fixed}</td>
                        <td>{row.WtPerYards}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Pagination */}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <button
                  className="btn btn-primary"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                  className="btn btn-primary"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReusableModal
        showModal={modalConfig.showModal}
        fieldName={modalConfig.fieldName}
        formName={modalConfig.formName}
        fieldValue={modalConfig.fieldValue}
        onClose={() => setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' })}
        onSelect={handleSelect}
      />
    </div >
  );
};

export default SqcQualityMaster;