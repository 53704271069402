import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from '../pages/Home';
import About from '../pages/About';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import LoginLocationSet from '../pages/LoginLocationSelect';
import Dashboard from '../pages/Dashboard';
import Layout from '../components/layout/Layout';
import SqcQualityMaster from '../pages/SQCQualityMaster';
import SQCEntry from '../pages/SQCEntry';
import SQCSpinning from '../pages/SQCSpinning';
import SQCReport from '../pages/SQCReport';
import ExportReport from '../pages/ExportReport';

const AppRoutes = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    } else {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<Login />} /> {/* Add the login route */}

      <Route
        path="/Home"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/LocationSelect"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <LoginLocationSet />
          </PrivateRoute>
        }
      />
      <Route
        path="/Dashboard"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Layout>
              <Dashboard />
            </Layout>
          </PrivateRoute>
        }
      />

      <Route
        path="/FrmSQCQualityMaster"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Layout>
              <SqcQualityMaster />
            </Layout>
          </PrivateRoute>
        }
      />

      <Route
        path="/FrmSQCEntry"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Layout>
              <SQCEntry />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/FrmSQCSpinning"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Layout>
              <SQCSpinning />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/FrmSQCReport"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Layout>
              <SQCReport />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/about"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <About />
          </PrivateRoute>
        }
      />
      <Route
        path="/Reports"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <ExportReport />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
    </Routes>
  );
};

export default AppRoutes;