import React, { useEffect, useState } from 'react';
import '../components/css/pages/SQCEntry.css';
import ReusableModal from '../components/modal/ReusableModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SQCEntry = () => {
  const sessionUserDetials = JSON.parse(sessionStorage.getItem('user'));
  const CompanyName = sessionUserDetials.compName;
  const Location = sessionUserDetials.location;
  const CompanyCode = sessionUserDetials.compCode;
  const userID = sessionUserDetials.userID;

  const [seriesData, setSeriesData] = useState([]);

  const [defaultDate, setDefaultDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formats as YYYY-MM-DD
  });

  const formatDate = (date) => {
    if (!date) return ""; // Handle null/undefined dates
    const d = new Date(date);
    return d.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  };

  const [saveFormData, setSaveFormData] = useState({});

  const [formData, setFormData] = useState({
    userId: userID,
    compCode: CompanyCode,
    compName: CompanyName,
    location: Location,
    chkReTest: 'N',
    stdSliverWt: '',
    docDate: defaultDate,
    series: '',
    department: '',
    departmentCode: '',
    process: '',
    shift: 'A',
    docNo: '',
    qualityCode: '',
    qualityName: '',
    machineCode: '',
    machineName: '',
    wtOfSampleGram: '',
    mrPerc: '',
    SampleLengthYDS: '0',
    FixedForHessianbySaking16and20: '0',
    WtYards: '0',
    WtPerYards: '0',
    Constant: '0',
    Fixed: '0',
    ActualWTlb: '0',
    CorrectedWTAt16by20parsentegMR: '0',
    // MRParsenteg: '',

    avgMean: '0',
    cvPerse: '0',
    rangeLowHigh: '0',
    repeitCVonlyForCalculation: '0',
    repeitCVOFCsubCrtWTAt16b20parsentegMR: '0',
    MeanSQR: '0',
    AVGE: '0',
    SDofAVGkaKarniasSD: '0',
    HIDDEN: '0',
    AD: '0'

  });

  // useEffect(() => {
  //   if (formData && formData.docDate) {
  //     console.log(formData.docDate);
  //     setFormData((prev) => ({
  //       ...prev, // Preserve existing properties
  //       docDate: formData.docDate, // Update only docDate
  //     }));
  //   }
  // }, [formData.]); // Only trigger when docDate changes

  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    formName: '',
    fieldName: '',
    fieldValue: '',
  });

  const handleFieldDoubleClick = (formName, fieldName, fieldValue) => {
    // Check if the field already has a value
    if (
      (fieldName === 'QualityName' && formData.qualityName)
      //  ||
      // (fieldName === 'MachineName' && formData.machineName)
    ) {
      // console.log(`Double-click prevented. ${fieldName} is already selected.`);
      return; // Do not open the modal
    }

    // Proceed to open the modal if no value is selected
    setModalConfig({
      showModal: true,
      formName,
      fieldName,
      fieldValue,
    });
  };

  const handleSelect = (fieldName, selectedRow) => {
    // console.log(fieldName, selectedRow);

    // Assuming selectedRow is an object containing department, departmentCode, and process
    if (fieldName === 'Department') {
      setFormData((prev) => ({
        ...prev,
        department: selectedRow.Department,
        departmentCode: selectedRow.Code,  // Assuming 'Code' is the department code in your data
        process: selectedRow.Process
      }));
    }

    if (fieldName === 'QualityName') {
      setFormData((prev) => ({
        ...prev,
        qualityCode: selectedRow.id,
        qualityName: selectedRow.QualityName,
        SampleLengthYDS: selectedRow.SampleLength,
        FixedForHessianbySaking16and20: selectedRow.FixedForHessianSaking,
        WtYards: selectedRow.WtPerYards,
        Constant: selectedRow.Constant,
        Fixed: selectedRow.Fixed,
      }));
    }

    if (fieldName === 'MachineName') {
      setFormData((prev) => ({
        ...prev,
        machineCode: selectedRow.mcode,
        machineName: selectedRow.mname,
      }));
    }

    // Close the modal after selection
    setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' });
  };

  const sqcTableheaders = [
    'SL No', 'Quality Code', 'Quality Name', 'Machine Code', 'Machine Name',
    'LB', 'TP', 'TPI', 'FRM SUM', 'Test DP', 'FRM NO', 'Sample  Length YDS',
    'Line Id', 'Wt Of sample Gram', 'Wt Yards', 'Wt per Yards', 'Constant',
    'Actual WT lb', 'MR', 'Fixed For Hessian Saking 16 &20', 'Fixed',
    'Corrected WT At 16/20 % MR', 'AVG(Mean)', 'repeit CV only For Calculation',
    'repeit CV OFC -(Corrected WT At 16/20 % MR)', 'Mean SQR', 'AVGE',
    'SD (AVGka Karni = SD)', 'CV %', 'Range(Low & High Value of corrected WT)',
    'HIDDEN', 'AD'
  ];

  const hiddenColumns = [
    'Quality Code', 'Quality Name', 'Machine Code', 'Machine Name',
    'LB', 'TP', 'TPI', 'FRM SUM', 'Test DP', 'FRM NO', 'Sample  Length YDS',
    'Wt Yards', 'Wt per Yards', 'Constant', 'Fixed For Hessian Saking 16 &20',
    'Fixed', 'repeit CV only For Calculation', 'repeit CV OFC -(Corrected WT At 16/20 % MR)',
    'Mean SQR', 'AVG', 'SD (AVGka Karni = SD)', 'HIDDEN', 'AD', 'AVGE', 'AVG(Mean)', 'CV %', 'Range(Low & High Value of corrected WT)'
  ];

  // const headerKeyMap = {
  //   'SL No': 'SLNo',
  //   'Quality Code': 'QualityCode',
  //   'Quality Name': 'QualityName',
  //   'Machine Code': 'MachineCode',
  //   'Machine Name': 'MachineName',
  //   'LB': 'LB',
  //   'TP': 'TP',
  //   'TPI': 'TPI',
  //   'FRM SUM': 'FRMSum',
  //   'Test DP': 'TestDP',
  //   'FRM NO': 'FRMNO',
  //   'Sample  Length YDS': 'SampleLengthYDS',
  //   'Line Id': 'LineId',
  //   'Wt Of sample Gram': 'WtOfsampleGram',
  //   'Wt Yards': 'WtYards',
  //   'Wt per Yards': 'WtperYards',
  //   'Constant': 'Constant',
  //   'Actual WT lb': 'ActualWTlb',
  //   'MR': 'MRParsenteg',
  //   'Fixed For Hessian Saking 16 &20': 'FixedForHessianbySaking16and20',
  //   'Fixed': 'Fixed',
  //   'Corrected WT At 16/20 % MR': 'CorrectedWTAt16by20parsentegMR',
  //   'AVG(Mean)': 'AVGMean',
  //   'repeit CV only For Calculation': 'repeitCVonlyForCalculation',
  //   'repeit CV OFC -(Corrected WT At 16/20 % MR)': 'repeitCVOFCsubCrtWTAt16b20parsentegMR',
  //   'Mean SQR': 'MeanSQR',
  //   'AVGE': 'AVGE',
  //   'SD (AVGka Karni = SD)': 'SDofAVGkaKarniasSD',
  //   'CV %': 'CVparsenteg',
  //   'Range(Low & High Value of corrected WT)': 'RangeLowandHighValueofcorrectedWT',
  //   'HIDDEN': 'HIDDEN',
  //   'AD': 'AD'
  // };

  const headerKeyMap = {
    'SL No': 'SLNo',
    'Quality Code': 'qualityCode',
    'Quality Name': 'qualityName',
    'Machine Code': 'machineCode',
    'Machine Name': 'machineName',
    'LB': 'LB',
    'TP': 'TP',
    'TPI': 'TPI',
    'FRM SUM': 'FRMSum',
    'Test DP': 'TestDP',
    'FRM NO': 'FRMNO',
    'Sample  Length YDS': 'SampleLengthYDS',
    'Line Id': 'LineId',
    'Wt Of sample Gram': 'wtOfSampleGram',
    'Wt Yards': 'WtYards',
    'Wt per Yards': 'WtPerYards',
    'Constant': 'Constant',
    'Actual WT lb': 'ActualWTlb',
    'MR': 'mrPerc',
    'Fixed For Hessian Saking 16 &20': 'FixedForHessianbySaking16and20',
    'Fixed': 'Fixed',
    'Corrected WT At 16/20 % MR': 'CorrectedWTAt16by20parsentegMR',
    'AVG(Mean)': 'avgMean',
    'repeit CV only For Calculation': 'repeitCVonlyForCalculation',
    'repeit CV OFC -(Corrected WT At 16/20 % MR)': 'repeitCVOFCsubCrtWTAt16b20parsentegMR',
    'Mean SQR': 'MeanSQR',
    'AVGE': 'AVGE',
    'SD (AVGka Karni = SD)': 'SDofAVGkaKarniasSD',
    'CV %': 'cvPerse',
    'Range(Low & High Value of corrected WT)': 'rangeLowHigh',
    'HIDDEN': 'HIDDEN',
    'AD': 'AD'
  };

  // const [tableData, setTableData] = useState([
  //   {
  //     SLNo: '',
  //     qualityCode: '',
  //     qualityName: '',
  //     machineCode: '',
  //     machineName: '',
  //     LB: '',
  //     TP: '',
  //     TPI: '',
  //     FRMSum: '',
  //     TestDP: '',
  //     FRMNO: '',
  //     SampleLengthYDS: '',
  //     LineId: '',
  //     wtOfsampleGram: '',
  //     WtYards: '',
  //     WtPerYards: '',
  //     Constant: '',
  //     ActualWTlb: '',
  //     MRParsenteg: '',
  //     FixedForHessianbySaking16and20: '',
  //     Fixed: '',
  //     CorrectedWTAt16by20parsentegMR: '',
  //     AVGMean: '',
  //     repeitCVonlyForCalculation: '',
  //     repeitCVOFCsubCrtWTAt16b20parsentegMR: '',
  //     MeanSQR: '',
  //     AVGE: '',
  //     SDofAVGkaKarniasSD: '',
  //     CVparsenteg: '',
  //     RangeLowandHighValueofcorrectedWT: '',
  //     HIDDEN: '',
  //     AD: ''
  //   }
  // ]);

  const [tableData, setTableData] = useState([]);

  // useEffect(() => {

  // }, [formData]);

  // useEffect(() => {

  // }, [tableData]);

  // useEffect(() => {
  //   setFormData({
  //     compCode: CompanyCode,
  //     compName: CompanyName,
  //     location: Location,

  //   })
  // }, [userID])

  // console.log(formData);

  useEffect(() => {
    const queryParameters = {
      compCode: CompanyCode,
      location: Location,
      frmName: 'FrmSQCEntry',
      objCode: 'SQC',
      userid: userID,
      docDate: formData.docDate || defaultDate,
      locked: 'N',
    };

    const url = `${API_BASE_URL}/api/getSQCSeries`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParameters), // Send data in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch series');
        }
        return response.json();
      })
      .then((data) => {
        setSeriesData(data); // Update state with fetched series data

        if (data.length > 0) {
          const firstSeries = data[0].Series; // Get the first series
          setFormData((prev) => ({ ...prev, series: firstSeries })); // Set initial series
          fetchDocNum(firstSeries); // Fetch DocNum for the first series
        }
      })
      .catch((error) => console.error('Error fetching series:', error));
  }, [CompanyCode, Location, userID, formData.docDate]);


  // SQC DocNum Fetch funciton
  const fetchDocNum = (selectedSeries) => {
    const queryParameters = {
      tableName: 'SQCEntry',
      formName: 'FrmSQCEntry',
      objCode: 'SQC',
      series: selectedSeries,
    };

    const url = `${API_BASE_URL}/api/getSQCDocNum`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParameters), // Send parameters in the body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch DocNum');
        }
        return response.json();
      })
      .then((data) => {
        setFormData((prev) => ({
          ...prev,
          docNo: data.docNum.toString().padStart(4, '0'), // Ensures 4-digit format
        }));
      })
      .catch((error) => console.error('Error fetching DocNum:', error));
  };

  const handleSeriesChange = (e) => {
    const selectedSeries = e.target.value;
    setFormData((prev) => ({ ...prev, series: selectedSeries }));
    fetchDocNum(selectedSeries);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setFormData((prev) => ({ ...prev, docDate: selectedDate })); // Update formData's docDate
  };

  // Handle input changes and update formData
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = { ...prev, [id]: value };

      // Perform calculations based on input
      if (id === "wtOfSampleGram") {
        const wtOfSampleGram = parseFloat(value) || 0;
        const sampleLengthYDS = parseFloat(prev.SampleLengthYDS) || 1;
        const wtYards = parseFloat(prev.WtYards) || 1;
        const constant = parseFloat(prev.Constant) || 1;

        const wtPerYards = wtOfSampleGram / (sampleLengthYDS * wtYards);
        const actualWTlb = (wtPerYards / constant) * 10000;

        updatedFormData.WtPerYards = wtPerYards.toFixed(2);
        updatedFormData.ActualWTlb = actualWTlb.toFixed(2);
      } else if (id === "mrPerc") {
        const actualWTlb = parseFloat(prev.ActualWTlb) || 0;
        const fixed = parseFloat(prev.Fixed) || 0;
        const fixedForHessianbySaking16and20 =
          parseFloat(prev.FixedForHessianbySaking16and20) || 0;
        const mRParsenteg = parseFloat(value) || 0;

        const correctedWTAt16by20parsentegMR =
          (actualWTlb * (fixed + fixedForHessianbySaking16and20)) /
          (mRParsenteg + fixed);

        updatedFormData.CorrectedWTAt16by20parsentegMR =
          correctedWTAt16by20parsentegMR.toFixed(2);
      }

      return updatedFormData;
    });
  };

  // Handle adding a new row to the table
  const handleAddRow = () => {
    setTableData((prevTable) => {
      // Create a new row using the current formData
      const newRow = {
        ...formData, // Ensure the latest formData is used
        SLNo: prevTable.length + 1, // Incremental SLNo
        LineId: prevTable.length + 1, // Incremental LineId
      };

      // console.log("Adding row:", newRow);

      // Add the new row to the table
      const updatedTable = [...prevTable, newRow];

      // Perform calculations based on the updated table
      const entryCount = updatedTable.length;
      const totalCorrectedWT = updatedTable.reduce((sum, row) => {
        return sum + (parseFloat(row.CorrectedWTAt16by20parsentegMR) || 0);
      }, 0);
      const avgMean = totalCorrectedWT / entryCount;

      // Calculate min and max for CorrectedWTAt16by20parsentegMR
      const correctedWTValues = updatedTable.map(
        (row) => parseFloat(row.CorrectedWTAt16by20parsentegMR) || 0
      );
      const minCorrectedWT = Math.min(...correctedWTValues);
      const maxCorrectedWT = Math.max(...correctedWTValues);

      // Create the RangeLowandHighValueofcorrectedWT string
      const RangeLowandHighValueofcorrectedWT = `${minCorrectedWT.toFixed(
        2
      )} - ${maxCorrectedWT.toFixed(2)}`;

      // Update each row with calculated values
      updatedTable.forEach((row) => {
        const correctedWT = parseFloat(row.CorrectedWTAt16by20parsentegMR) || 0;
        const subCrtWT = avgMean - correctedWT;
        row.MeanSQR = (subCrtWT * subCrtWT).toFixed(2); // GrdColMeanSQR
      });

      const totalMeanSQR = updatedTable.reduce((sum, row) => {
        return sum + (parseFloat(row.MeanSQR) || 0);
      }, 0);
      const avge = totalMeanSQR / entryCount;
      const sdOfAVGkaKarniasSD = Math.sqrt(avge);
      const cvPerse = (sdOfAVGkaKarniasSD * 100) / avgMean;

      // Update formData with the new calculated values
      setFormData((prevFormData) => ({
        ...prevFormData,
        avgMean: avgMean.toFixed(2),
        repeitCVonlyForCalculation: avgMean.toFixed(2),
        AVGE: avge.toFixed(2),
        SDofAVGkaKarniasSD: sdOfAVGkaKarniasSD.toFixed(2),
        cvPerse: cvPerse.toFixed(2),
        rangeLowHigh: RangeLowandHighValueofcorrectedWT, // Add the formatted range
      }));

      return updatedTable; // Return the updated table
    });

    // Reset specific fields in formData after adding the row
    setFormData((prev) => ({
      ...prev,
      wtOfSampleGram: "",
      mrPerc: "",
    }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    console.log("Form data at submit time:", formData); // Log formDat

    // Prepare the data to save
    setSaveFormData(formData); // Ensure saveFormData contains the latest formData

    try {
      const response = await fetch(`${API_BASE_URL}/api/sqc-entry-insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          formData: {
            userId: formData.userId,
            compCode: formData.compCode,
            compName: formData.compName,
            location: formData.location,
            chkReTest: formData.chkReTest,
            stdSliverWt: formData.stdSliverWt,
            docDate: formData.docDate,
            series: formData.series,
            department: formData.department,
            departmentCode: formData.departmentCode,
            process: formData.process,
            shift: formData.shift,
            docNo: formData.docNo,
            qualityCode: formData.qualityCode,
            qualityName: formData.qualityName,
            avgMean: formData.avgMean,
            cvPerse: formData.cvPerse,
            rangeLowHigh: formData.rangeLowHigh,
            repeitCVonlyForCalculation: formData.repeitCVonlyForCalculation,
            repeitCVOFCsubCrtWTAt16b20parsentegMR: formData.repeitCVOFCsubCrtWTAt16b20parsentegMR,
            MeanSQR: formData.MeanSQR,
            AVGE: formData.AVGE,
            SDofAVGkaKarniasSD: formData.SDofAVGkaKarniasSD,
            HIDDEN: formData.HIDDEN,
            AD: formData.AD
          },
          tableData: tableData.map((row) => ({
            machineCode: row.machineCode,
            machineName: row.machineName,
            wtOfSampleGram: row.wtOfSampleGram,
            mrPerc: row.mrPerc,
            SampleLengthYDS: row.SampleLengthYDS,
            FixedForHessianbySaking16and20: row.FixedForHessianbySaking16and20,
            WtYards: row.WtYards,
            WtPerYards: row.WtPerYards,
            Constant: row.Constant,
            Fixed: row.Fixed,
            ActualWTlb: row.ActualWTlb,
            CorrectedWTAt16by20parsentegMR: row.CorrectedWTAt16by20parsentegMR,
            SLNo: row.SLNo,
            LineId: row.LineId
          }))
        }),
      });

      const result = await response.json();
      console.log("Response from backend:", result);
      if (response.ok) {
        alert("Data saved successfully!");

        // Reset form and table data
        setFormData({
          compCode: CompanyCode,
          compName: CompanyName,
          location: Location,
          chkReTest: 'N',
          stdSliverWt: '',
          docDate: defaultDate,
          department: '',
          departmentCode: '',
          process: '',
          shift: 'A',
          docNo: '',
          qualityCode: '',
          qualityName: '',
          machineCode: '',
          machineName: '',
          wtOfSampleGram: '',
          mrPerc: '',
          SampleLengthYDS: '',
          FixedForHessianbySaking16and20: '',
          WtYards: '',
          WtPerYards: '',
          Constant: '',
          Fixed: '',
          ActualWTlb: '',
          CorrectedWTAt16by20parsentegMR: '',
          avgMean: '',
          cvPerse: '',
          rangeLowHigh: '',
          repeitCVonlyForCalculation: '',
          repeitCVOFCsubCrtWTAt16b20parsentegMR: '',
          MeanSQR: '',
          AVGE: '',
          SDofAVGkaKarniasSD: '',
          HIDDEN: '',
          AD: '',
        });
        setTableData([]);
        fetchDocNum(formData.series);
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while saving the data.");
    }
  };

  return (
    <div className='sqc-form-container'>
      <div className='container-head'>
        {/* <h6>SQC Entry</h6> */}
      </div>
      <form onSubmit={handleSubmitForm}>
        <div className="container-head-details">
          <div className="row d-flex justify-content-between">
            {/* Left Column */}
            <div className="col-md-4 col-lg-4">
              <div className="form-group row">
                <label htmlFor="compName" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Company</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <input type="text"
                    className='form-control'
                    id='compName'
                    name='compName'
                    value={formData.compName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        compName: e.target.value,
                      }))
                    }
                    placeholder=''
                    disabled
                  />
                </div>
              </div>
              <div style={{ marginTop: '5px' }} className="form-group row">
                <label htmlFor="location" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Location</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <input type="text"
                    className='form-control'
                    id='compName'
                    name='location'
                    value={formData.location}
                    placeholder=''
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        location: e.target.value,
                      }))
                    }
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 mt-2">
                <div className="form-check">
                  <input type="checkbox"
                    id='chkReTest'
                    className="form-check-input"
                    checked={formData.chkReTest === 'Y'}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        chkReTest: e.target.checked ? 'Y' : 'N',
                      })
                    }
                  />
                  <label htmlFor="chkReTest" className="form-check-label">ReTest</label>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="stdSliverWt" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Std Sliver Wt.</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <input type="text"
                    className='form-control'
                    id='stdSliverWt'
                    value={formData.stdSliverWt}
                    onChange={(e) => setFormData({
                      ...formData,
                      stdSliverWt: e.target.value
                    })}
                    placeholder=''
                  />
                </div>
              </div>
            </div>
            {/* Right Column */}
            <div className="col-md-5 col-lg-5">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="docDate" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Date</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="date"
                        id='docDate'
                        className="form-control"
                        value={formData.docDate || defaultDate} // Use formData or default date
                        onChange={handleDateChange} // Handle date changes
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="series" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Series</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <select name="series"
                        id="series"
                        className='form-select'
                        value={formData.series}
                        onChange={handleSeriesChange}
                      >
                        {seriesData.map((item) => (
                          <option key={item.Series} value={item.Series}>
                            {item.Series}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="department" className='mt-1 col-md-4 col-lg-4 col-sm-4'>Department</label>
                    <div className="col-md-8 col-lg-8 col-sm-8">
                      <input type="text"
                        id='department'
                        className="form-control"
                        placeholder='Select Department'
                        value={formData.department}
                        onDoubleClick={() => handleFieldDoubleClick('FrmSQCEntry', 'Department', '')}
                        // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="shift" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Shift</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <select name="series"
                        id="shift"
                        className='form-select'
                        value={formData.shift}
                        onChange={(e) =>
                          setFormData((prev) => ({ ...prev, shift: e.target.value }))
                        }
                      >
                        {/* <option value="" disabled>--Select--</option> */}
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="docNum" className='mt-1 col-md-4 col-lg-4 col-sm-4'>Doc Num</label>
                    <div className="col-md-8 col-lg-8 col-sm-8">
                      <input type="text"
                        id='docNum'
                        className="form-control"
                        placeholder=''
                        value={formData.docNo}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="departmentCode" className='mt-1 col-md-3 col-lg-3 col-sm-3'></label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='departmentCode'
                        className="form-control"
                        placeholder=''
                        value={formData.departmentCode}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="process" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Process</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='process'
                        className="form-control"
                        placeholder=''
                        value={formData.process}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-input-field-details'>
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="quality">Quality Code/Name</label>
                <div className="input-group">
                  <input type="text"
                    name="qualityCode"
                    id="qualityCode"
                    className='form-control w-25'
                    value={formData.qualityCode}
                    readOnly
                  />
                  <div className="input-group-append w-75">
                    <input
                      type="text"
                      name="qualityName"
                      id="qualityName"
                      className="form-control"
                      value={formData.qualityName}
                      placeholder="Select Quality Name"
                      onDoubleClick={() =>
                        handleFieldDoubleClick('FrmSQCEntry', 'QualityName',
                          {
                            departmentCode: formData.departmentCode,
                            department: formData.department,
                            process: formData.process,
                            qualityName: formData.qualityName,
                          })
                      }
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="machine">Machine Code/Name</label>
                  <div className="input-group">
                    <input type="text"
                      name="machineCode"
                      id="machineCode"
                      className='form-control w-25'
                      value={formData.machineCode}
                      readOnly
                    />
                    <div className="input-group-append w-75">
                      <input type="text"
                        name="machineName"
                        id="machineName"
                        className='form-control'
                        placeholder='Select Machine Name'
                        value={formData.machineName}
                        onDoubleClick={() =>
                          handleFieldDoubleClick('FrmSQCEntry', 'MachineName',
                            {
                              machineName: formData.machineName,
                            }
                          )}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="wtOfSampleGram">wt of Sample Gram</label>
                <input type="text"
                  id='wtOfSampleGram'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.wtOfSampleGram}
                  // onChange={(e) => setFormData({ ...formData, wtOfSampleGram: e.target.value })}
                  onChange={handleInputChange} // Attach the onChange handler
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="mrPerc">MR</label>
                <input type="text"
                  id='mrPerc'
                  className='form-control'
                  placeholder='Enter MR'
                  value={formData.mrPerc}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <button
                type='button'
                className="btn btn-primary"
                style={{ marginTop: '30px' }}
                onClick={handleAddRow}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="container-grid-details">
          <table className="table table-bordered">
            <thead>
              <tr>
                {sqcTableheaders.map((header, index) =>
                  !hiddenColumns.includes(header) ? <th key={index}>{header}</th> : null
                )}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {sqcTableheaders.map((header, colIndex) =>
                      !hiddenColumns.includes(header) ? (
                        <td key={colIndex}>{row[headerKeyMap[header]] || ''}</td>
                      ) : null
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={sqcTableheaders.length}>No data available</td>
                </tr>
              )}
              {/* <tr>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>Average Mean:</strong> {formData.avgMean || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>repeitCV:</strong> {formData.repeitCVonlyForCalculation || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>repeitCV2:</strong> {formData.repeitCVOFCsubCrtWTAt16b20parsentegMR || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>Mean SQR:</strong> {formData.MeanSQR || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>AVGE:</strong> {formData.AVGE || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>SD (AVGka Karni = SD):</strong> {formData.SDofAVGkaKarniasSD || '0.00'}
                </td>
                <td colSpan={Object.keys(headerKeyMap).length} style={{ textAlign: 'right' }}>
                  <strong>SD (AVGka Karni = SD):</strong> {formData.cvPerse || '0.00'}
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
        <div className='container-footer-input-field-details mt-3'>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-sm-4">
              <div className="input-group row">
                <label htmlFor="avgMean" className='col-md-4 col-lg-4 col-sm-4 mt-2'>AVG Mean</label>
                <div className="col-md-8 col-lg-8 col-sm-8">
                  <input type="text"
                    name='avgMean'
                    id='avgMean'
                    className="form-control"
                    value={formData.avgMean}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        avgMean: e.target.value,
                      }))
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-sm-4">
              <div className="input-group row">
                <label htmlFor="cvPerse" className='col-md-4 col-lg-4 col-sm-4 mt-2'>CV %</label>
                <div className="col-md-8 col-lg-8 col-sm-8">
                  <input type="text"
                    name='cvPerse'
                    id='cvPerse'
                    className="form-control"
                    value={formData.cvPerse}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        cvPerse: e.target.value,
                      }))
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-4">
              <div className="input-group row">
                <label htmlFor="rangeLowHigh" className='col-md-4 col-lg-4 col-sm-4 mt-2'>
                  Range(Low & High Value of corrected WT)
                </label>
                <div className="col-md-5 col-lg-5 col-sm-5">
                  <input type="text"
                    id='rangeLowHigh'
                    name='rangeLowHigh'
                    className="form-control"
                    value={formData.rangeLowHigh}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        rangeLowHigh: e.target.value,
                      }))
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-button-details">
          <button type='submit' className="btn btn-primary mx-2">Save</button>
          <button type='button' className="btn btn-secondary mx-2">Cancel</button>
        </div>
      </form>

      <ReusableModal
        showModal={modalConfig.showModal}
        fieldName={modalConfig.fieldName}
        formName={modalConfig.formName}
        fieldValue={modalConfig.fieldValue}
        onClose={() => setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' })}
        onSelect={handleSelect}
      />
    </div >
  )
}

export default SQCEntry;