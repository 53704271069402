import React from 'react';

const Dashboard = () => {
  return (
    <div className='container-head'>
      <h5>Dashboard</h5>
    </div>
  );
};

export default Dashboard;