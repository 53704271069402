import React, { useState, useEffect } from 'react';
import '../components/css/pages/SQCSpinning.css';
import ReusableModal from '../components/modal/ReusableModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SQCSpinning = () => {
  const sessionUserDetials = JSON.parse(sessionStorage.getItem('user'));
  const CompanyName = sessionUserDetials.compName;
  const Location = sessionUserDetials.location;
  const CompanyCode = sessionUserDetials.compCode;
  const UserID = sessionUserDetials.userID;

  // Default Date
  const [defaultDate, setDefaultDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formats as YYYY-MM-DD
  })

  // Table Structure defined below
  const [tableData, setTableData] = useState([]);

  const sqcTableheaders = [
    'SL No', 'Quality Code', 'Quality Name', 'Machine Code', 'Machine Name',
    'LB', 'TP', 'TPI', 'FRM SUM', 'Test DP', 'FRM NO', 'Sample  Length YDS',
    'Line Id', 'Wt Of sample Gram', 'Wt Yards', 'Wt per Yards', 'Constant',
    'Actual WT lb', 'MR', 'Fixed For Hessian Saking 16 &20', 'Fixed',
    'Corrected WT At 16/20 % MR', 'NO.OF Frame', 'TDPNOF', 'AVG (Mean)',
    'repeit CV only For Calculation', 'repeit CV OFC -(Corrected WT At 16/20 % MR)',
    'Mean SQR', 'AVG', 'SD (AVGka Karni = SD)', 'CV %',
    'Range(Low & High Value of corrected WT)', 'HIDDEN', 'AD'
  ];

  const hiddenColumns = [
    'Quality Code', 'Machine Code', 'Sample  Length YDS', 'Wt Yards',
    'Wt per Yards', 'Constant', 'Fixed For Hessian Saking 16 &20',
    'Fixed', 'NO.OF Frame', 'TDPNOF', 'repeit CV only For Calculation',
    'repeit CV OFC -(Corrected WT At 16/20 % MR)', 'Mean SQR', 'AVG',
    'SD (AVGka Karni = SD)', 'CV %',
    'Range(Low & High Value of corrected WT)', 'HIDDEN', 'AD'
  ];

  const headerKeyMap = {
    'SL No': 'SLNo',
    'Quality Code': 'qualityCode',
    'Quality Name': 'qualityName',
    'Machine Code': 'machineCode',
    'Machine Name': 'machineName',
    'LB': 'LB',
    'TP': 'TP',
    'TPI': 'TPI',
    'FRM SUM': 'FRMSum',
    'Test DP': 'TestDP',
    'FRM NO': 'FRMNO',
    'Sample  Length YDS': 'SampleLengthYDS',
    'Line Id': 'LineId',
    'Wt Of sample Gram': 'wtOfSampleGram',
    'Wt Yards': 'WtYards',
    'Wt per Yards': 'WtPerYards',
    'Constant': 'Constant',
    'Actual WT lb': 'ActualWTlb',
    'MR': 'mrPercentage',
    'Fixed For Hessian Saking 16 &20': 'FixedForHessianbySaking16and20',
    'Fixed': 'Fixed',
    'Corrected WT At 16/20 % MR': 'CorrectedWTAt16by20parsentegMR',
    'NO.OF Frame': 'noOfFrame',
    'TDPNOF': 'TDPNOF',
    'AVG(Mean)': 'avgMean',
    'repeit CV only For Calculation': 'repeitCVonlyForCalculation',
    'repeit CV OFC -(Corrected WT At 16/20 % MR)': 'repeitCVOFCsubCrtWTAt16b20parsentegMR',
    'Mean SQR': 'MeanSQR',
    'AVG': 'AVG',
    'SD (AVGka Karni = SD)': 'SDofAVGkaKarniasSD',
    'CV %': 'cvPerse',
    'Range(Low & High Value of corrected WT)': 'rangeLowHigh',
    'HIDDEN': 'HIDDEN',
    'AD': 'AD'
  };

  // const formatDate = (date) => {
  //   if (!date) return ""; // Handle null/undefined dates
  //   const d = new Date(date);
  //   return d.toISOString().split("T")[0]; // Convert to YYYY-MM-DD
  // };

  // Form Fields Data Below
  const [formData, setFormData] = useState({
    userID: UserID,
    compCode: CompanyCode,
    compName: CompanyName,
    location: Location,
    series: '',
    docDate: defaultDate,
    chkReTest: 'N',
    shift: 'A',
    docNo: '',
    departmentName: '',
    departmentCode: '',
    process: '',
    qualityCode: '',
    qualityName: '',
    machineCode: '',
    machineName: '',
    wtOfSampleGram: null,
    mrPercentage: '',
  });
  const [seriesData, setSeriesData] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    showModal: false,
    formName: '',
    fieldName: '',
    fieldValue: '',
  });

  // Get SQC Series
  useEffect(() => {
    const queryParameters = {
      compCode: CompanyCode,
      location: Location,
      frmName: 'FrmSQCSPINNING',
      objCode: 'SQC',
      userid: UserID,
      docDate: formData.docDate || defaultDate,
      locked: 'N',
    };

    const url = `${API_BASE_URL}/api/getSQCSeries`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParameters), // Send data in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch series');
        }
        return response.json();
      })
      .then((data) => {
        setSeriesData(data); // Update state with fetched series data

        if (data.length > 0) {
          const firstSeries = data[0].Series; // Get the first series
          setFormData((prev) => ({ ...prev, series: firstSeries })); // Set initial series
          fetchDocNum(firstSeries); // Fetch DocNum for the first series
        }
      })
      .catch((error) => console.error('Error fetching series:', error));
  }, [CompanyCode, Location, UserID, formData.docDate, defaultDate]);

  const handleSeriesChange = (e) => {
    const selectedSeries = e.target.value;
    setFormData((prev) => ({ ...prev, series: selectedSeries }));
    fetchDocNum(selectedSeries);
  };

  // SQC DocNum Fetch funciton
  const fetchDocNum = (selectedSeries) => {
    const queryParameters = {
      tableName: 'SQCEntry',
      formName: 'FrmSQCSPINNING',
      objCode: 'SQC',
      series: selectedSeries,
    };

    const url = `${API_BASE_URL}/api/getSQCDocNum`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParameters), // Send parameters in the body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch DocNum');
        }
        return response.json();
      })
      .then((data) => {
        setFormData((prev) => ({
          ...prev,
          docNo: data.docNum.toString().padStart(4, '0'), // Ensures 4-digit format
        }));
      })
      .catch((error) => console.error('Error fetching DocNum:', error));
  };

  // Modal Open on Double click
  const handleFieldDoubleClick = (formName, fieldName, fieldValue) => {
    // Check if the field already has a value
    if (
      (fieldName === 'QualityName' && formData.qualityName)
      //  ||
      // (fieldName === 'MachineName' && formData.machineName)
    ) {
      // console.log(`Double-click prevented. ${fieldName} is already selected.`);
      return; // Do not open the modal
    }

    // Proceed to open the modal if no value is selected
    setModalConfig({
      showModal: true,
      formName,
      fieldName,
      fieldValue,
    });
  };

  const handleSelect = (fieldName, selectedRow) => {
    // console.log(fieldName, selectedRow);

    // Assuming selectedRow is an object containing department, departmentCode, and process
    if (fieldName === 'Department') {
      setFormData((prev) => ({
        ...prev,
        department: selectedRow.Department,
        departmentCode: selectedRow.Code,  // Assuming 'Code' is the department code in your data
        process: selectedRow.Process
      }));
    }

    if (fieldName === 'QualityName') {
      setFormData((prev) => ({
        ...prev,
        qualityCode: selectedRow.id,
        qualityName: selectedRow.QualityName,
        SampleLengthYDS: selectedRow.SampleLength,
        FixedForHessianbySaking16and20: selectedRow.FixedForHessianSaking,
        WtYards: selectedRow.WtPerYards,
        Constant: selectedRow.Constant,
        Fixed: selectedRow.Fixed,
      }));
    }

    if (fieldName === 'MachineName') {
      setFormData((prev) => ({
        ...prev,
        machineCode: selectedRow.mcode,
        machineName: selectedRow.mname,
      }));
    }

    // Close the modal after selection

    setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' });
  };

  return (
    <div className="sqc-form-container">
      <div className='container-head'>
        <h5>Spinning Entry</h5>
      </div>
      <form>
        <div className="container-head-details">
          <div className="row d-flex justify-content-between">
            {/* Left Column */}
            <div className="col-md-4 col-lg-4">
              <div className="form-group row">
                <label htmlFor="compName" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Company</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <div className="input-group">
                    <input type="text"
                      className='form-control'
                      id='compName'
                      name='compName'
                      value={formData.compName}
                      disabled
                    />
                    <div className="input-group-append w-25">
                      <input type="text"
                        id='compCode'
                        className="form-control"
                        placeholder=''
                        value={formData.compCode}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '5px' }} className="form-group row">
                <label htmlFor="location" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Location</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <input type="text"
                    className='form-control'
                    id='compName'
                    name='location'
                    value={formData.location}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 mt-2">
                <div className="form-check">
                  <input type="checkbox"
                    id='chkReTest'
                    className="form-check-input"
                    checked={formData.chkReTest === 'Y'}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        chkReTest: e.target.checked ? 'Y' : 'N',
                      })
                    }
                  />
                  <label htmlFor="chkReTest" className="form-check-label">ReTest</label>
                </div>
              </div>
              {/* <div className="form-group row">
                <label htmlFor="stdSliverWt" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Std Sliver Wt.</label>
                <div className="col-md-9 col-lg-9 col-sm-9">
                  <input type="text"
                    className='form-control'
                    id='stdSliverWt'
                    value={formData.stdSliverWt}
                    onChange={(e) => setFormData({
                      ...formData,
                      stdSliverWt: e.target.value
                    })}
                    placeholder=''
                  />
                </div>
              </div> */}
            </div>
            {/* Right Column */}
            <div className="col-md-6 col-lg-6">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="docDate" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Date</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="date"
                        id='docDate'
                        className="form-control"
                        value={formData.docDate || defaultDate} // Use formData or default date
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev, docDate: e.target.value
                          }))
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="series" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Series</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <select name="series"
                        id="series"
                        className='form-select'
                        value={formData.series}
                        onChange={handleSeriesChange}
                      >
                        {seriesData.map((item) => (
                          <option key={item.Series} value={item.Series}>
                            {item.Series}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="department" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Department</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='department'
                        className="form-control"
                        placeholder='Select Department'
                        value={formData.department}
                        onDoubleClick={() => handleFieldDoubleClick('FrmSQCSpinning', 'Department', '')}
                        // onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="shift" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Shift</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <select name="series"
                        id="shift"
                        className='form-select'
                        value={formData.shift}
                        onChange={(e) =>
                          setFormData((prev) => ({ ...prev, shift: e.target.value }))
                        }
                      >
                        {/* <option value="" disabled>--Select--</option> */}
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="docNum" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Doc Num</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='docNum'
                        className="form-control"
                        placeholder=''
                        value={formData.docNo}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="departmentCode" className='mt-1 col-md-3 col-lg-3 col-sm-3'></label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='departmentCode'
                        className="form-control"
                        placeholder=''
                        value={formData.departmentCode}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="form-group row">
                    <label htmlFor="process" className='mt-1 col-md-3 col-lg-3 col-sm-3'>Process</label>
                    <div className="col-md-9 col-lg-9 col-sm-9">
                      <input type="text"
                        id='process'
                        className="form-control"
                        placeholder=''
                        value={formData.process}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-input-field-details'>
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="quality">Quality Code/Name</label>
                <div className="">
                  {/* <input type="text"
                    name="qualityCode"
                    id="qualityCode"
                    className='form-control w-25'
                    value={formData.qualityCode}
                    readOnly
                  /> */}
                  <div className="">
                    <input
                      type="text"
                      name="qualityName"
                      id="qualityName"
                      className="form-control"
                      value={formData.qualityName}
                      placeholder="Select Quality Name"
                      onDoubleClick={() =>
                        handleFieldDoubleClick('FrmSQCEntry', 'QualityName',
                          {
                            departmentCode: formData.departmentCode,
                            department: formData.department,
                            process: formData.process,
                            qualityName: formData.qualityName,
                          })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="machine">Machine Code/Name</label>
                  <div className="">
                    {/* <input type="text"
                      name="machineCode"
                      id="machineCode"
                      className='form-control w-25'
                      value={formData.machineCode}
                      readOnly
                    /> */}
                    <div className="">
                      <input type="text"
                        name="machineName"
                        id="machineName"
                        className='form-control'
                        placeholder='Select Machine Name'
                        value={formData.machineName}
                        onDoubleClick={() =>
                          handleFieldDoubleClick('FrmSQCEntry', 'MachineName',
                            {
                              machineName: formData.machineName,
                            }
                          )}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="rowLB">LB</label>
                <input type="text"
                  id='rowLB'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.LB}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="TP">TP</label>
                <input type="text"
                  id='TP'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.TP}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="TPI">TPI</label>
                <input type="text"
                  id='TPI'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.TPI}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="testDP">Test DP</label>
                <input type="text"
                  id='testDP'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.testDP}
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="form-group">
                <label htmlFor="frmNo">FRM NO</label>
                <input type="text"
                  id='frmNo'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.frmNo}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="wtOfSampleGram">wt of Sample Gram</label>
                <input type="text"
                  id='wtOfSampleGram'
                  className='form-control'
                  placeholder='Enter Value'
                  value={formData.wtOfSampleGram}
                // onChange={(e) => setFormData({ ...formData, wtOfSampleGram: e.target.value })}
                // onChange={handleInputChange} // Attach the onChange handler
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <div className="form-group">
                <label htmlFor="mrPerc">MR</label>
                <input type="text"
                  id='mrPerc'
                  className='form-control'
                  placeholder='Enter MR'
                  value={formData.mrPercentage}
                // onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-2 col-lg-2">
              <button
                type='button'
                className="btn btn-primary"
                style={{ marginTop: '30px' }}
              // onClick={handleAddRow}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="container-grid-details">
          <table className="table table-bordered">
            <thead>
              <tr>
                {sqcTableheaders.map((header, index) =>
                  !hiddenColumns.includes(header) ? <th key={index}>{header}</th> : null
                )}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {sqcTableheaders.map((header, colIndex) =>
                      !hiddenColumns.includes(header) ? (
                        <td key={colIndex}>{row[headerKeyMap[header]] || ''}</td>
                      ) : null
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={sqcTableheaders.length}>No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
      <ReusableModal
        showModal={modalConfig.showModal}
        fieldName={modalConfig.fieldName}
        formName={modalConfig.formName}
        fieldValue={modalConfig.fieldValue}
        onClose={() => setModalConfig({ showModal: false, fieldName: '', formName: '', fieldValue: '' })}
        onSelect={handleSelect}
      />
    </div>

  );
};

export default SQCSpinning;