import React, { useState } from 'react';
import axios from 'axios';

const ReportDownloadButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadReport = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get('http://localhost:5000/generate-report', {
        responseType: 'blob', // Important to handle binary data
      });

      // Create a Blob from the PDF response
      const file = new Blob([response.data], { type: 'application/pdf' });

      // Open the PDF in a new tab
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL); // Opens the PDF in a new tab or window
    } catch (err) {
      console.error('Error fetching report:', err);
      setError('Error generating report');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={downloadReport} disabled={isLoading}>
        {isLoading ? 'Generating Report...' : 'Download Report'}
      </button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default ReportDownloadButton;
